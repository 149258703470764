import request from '@/utils/request'

// 获取笔记
export function getNoteList(data) {
  return request({
    url: 'Note/GetNoteList',
    data,
    method: 'POST'
  })
}

// 添加笔记
export function addNode(data) {
  return request({
    url: 'Note/AddNote',
    data,
    method: 'POST'
  })
}
